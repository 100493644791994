import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { useAppDispatch } from "../../store/hooks";
import { setJoining, setLoadingEvent } from "../../store/pageState";
import { ANIMATION_TIME } from "../../utils/constants";
import { Instructions as InstructionsElement } from "../../components/Instructions/Instructions";
import theme from "../../utils/theme";

const Instructions = (): JSX.Element => {

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [springs, api] = useSpring(() => ({
        from: { opacity: 0 },
    }));

    const fadeIn = () => {
        api.start({
            from: { opacity: 0 },
            to: { opacity: 1 },
        })
    };

    // listening for when should fade in the time table
    useEffect(() => {
        fadeIn();
    }, []);

    const marginValue = isMobile ? 20 : 50;
    
    return (
        <animated.div style={{...springs, height: '100vh', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
            <Typography variant="h1" sx={{mb: marginValue, mt: marginValue}}>How To Use</Typography>
            <InstructionsElement />
        </animated.div>
    )
}

export default Instructions;