import { Box, Fade, Typography, useMediaQuery } from "@mui/material";
import { useAppDispatch } from "../../store/hooks";
import { setAlertOpen, setInstructions } from "../../store/pageState";
import { useGetPageState } from "../../store/selectors/pageStateSelector";
import { useGetSettingsInfo } from "../../store/selectors/settingSelector";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import theme, { DARK_ORANGE, LIGHT_ORANGE } from "../../utils/theme";
import { ANIMATION_TIME } from "../../utils/constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../../config/config";

/**
 * Text at the top of the home page
 */
const HeaderText = (): JSX.Element => {
    const settings = useGetSettingsInfo();
    const pageState = useGetPageState();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [shouldHide, setShouldHide] = useState<boolean>(pageState.joining as boolean);
    const [hasBegunTransition, setHasBegunTransition] = useState<boolean>(false);
    const showEventDetails = (
            pageState.createdEvent && 
            !pageState.waitingEvent && 
            !shouldHide && 
            hasBegunTransition
        ) ||
        pageState.joining;

    useEffect(() => {
        if ((pageState.createdEvent && !pageState.waitingEvent) || pageState.joining) {
            setShouldHide(true);
            setHasBegunTransition(true);
            setTimeout(() => {
                setShouldHide(false);
            }, ANIMATION_TIME);
        }
    }, [pageState.createdEvent, pageState.waitingEvent, pageState.joining]);

    return (
        <Box sx={{flex: 0.8, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <Typography variant={isMobile ? "h3" : "h1"} sx={{margin: isMobile ? 20 : 50, mb: isMobile ? 5 : 10, userSelect: 'none'}}>
                    {showEventDetails ? `${settings.eventName}` : `Time to Meet`}
                </Typography>
                {
                    showEventDetails && <Typography sx={{mb: isMobile ? 10 : 20, userSelect: 'none'}} variant={isMobile ? "h6" : "h4"}>{`Time Zone: ${settings.timeZone}`}</Typography>
                }
                {
                    !showEventDetails && 
                    <Box 
                        border={1}
                        sx={{
                            display: 'flex', 
                            flexDirection: 'row', 
                            mb: 40, 
                            pl: 15,
                            pr: 15,
                            color: DARK_ORANGE,
                            borderRadius: 5,
                            backgroundColor: 'white',
                            borderColor: DARK_ORANGE,
                            userSelect: 'none', 
                            alignItems: 'center',
                            '&:hover': {
                                cursor: 'pointer',
                                backgroundColor: LIGHT_ORANGE,
                            },
                        }}
                        onClick={() => {
                            navigate(`/how-to`);
                        }}
                    >
                        <Typography 
                            variant="h6" 
                            fontStyle={'italic'} 
                            color="inherit" 
                            sx={{ mr: 5 }}
                        > 
                            How to use ?
                        </Typography>
                    </Box>
                }
                {
                    showEventDetails &&
                    <Box 
                        onClick={() => {
                            navigator.clipboard.writeText(`${config.name === 'local' ? 'localhost:3000' : config.name === 'dev' ? 'www.dev.timetomeet.net' : 'www.timetomeet.net'}/${settings.eventID as string}`);
                            dispatch(setAlertOpen({copyAlertOpen: true}));
                        }}
                        sx={{
                            display: 'flex', 
                            flexDirection: 'row',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                            backgroundColor: LIGHT_ORANGE,
                            padding: isMobile ? 5 : 10,
                            mb: isMobile ? 10 : 20,  
                            borderRadius: 2,
                            borderColor: DARK_ORANGE,
                            color: DARK_ORANGE,
                        }}
                        border={1}
                    >
                        <Typography 
                            variant={isMobile ? "h6" : "h4"}
                            sx={{
                                mr: 10,
                                color: DARK_ORANGE,
                                userSelect: 'none',
                            }}
                        >{`Copy Event Link`}</Typography>
                        <ContentCopyIcon color='inherit'/>
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default HeaderText;