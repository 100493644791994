import { Box, Typography, useMediaQuery } from "@mui/material";
import { useGetPageState } from "../../store/selectors/pageStateSelector";
import { useGetSettingsInfo } from "../../store/selectors/settingSelector";
import { alterColor } from "../../utils/functions";
import theme from "../../utils/theme";

const ColorLegend = (): JSX.Element | null => {
    const pageState = useGetPageState();
    const settings = useGetSettingsInfo();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    if (pageState.groupTimes === undefined) {
        return null;
    }
    const usersResponded: Set<string> = new Set();
    for (const cell of pageState.groupTimes) {
        if (cell !== undefined) {
            for (const id of cell) {
                usersResponded.add(id);
            }
        }
    }
    const responses: Array<string> = [...usersResponded];

    return (
        <Box sx={{width: isMobile ? '45%' : '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: 80, mt: isMobile ? 0 : 20}}>
            <Typography sx={{height: 20, mb: isMobile ? 5 : 10, userSelect: 'none'}} fontStyle="italic" fontSize={isMobile ? 10 : 'inherit'} >number of people available</Typography>
            <Box sx={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                <Box sx={{flex: 1, backgroundColor: theme.palette.secondary.main, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography sx={{color: 'black', userSelect: 'none'}} variant={responses.length < 3 ? "h4" : "h5"}>{`0`}</Typography>
                </Box>
                {responses.map((_res: string, index: number) => {
                    const bgColor = alterColor(theme.palette.secondary.main, '#0095ff', responses.length === 0 ? 0 : (index+1)/responses.length);

                    return (
                        <Box key={index} sx={{flex: 1, backgroundColor: bgColor, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography sx={{color: 'black', userSelect: 'none'}} variant={responses.length < 3 ? "h4" : "h5"}>{index+1}</Typography>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
}

export default ColorLegend;