import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SliceState = {
    mouseDown?: boolean,
    warningAlertOpen?: boolean,
}

const initialState: SliceState = {
    mouseDown: false,
    warningAlertOpen: false,
};

const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        setMouseState(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.mouseDown = p.mouseDown ?? state.mouseDown;
        },
        setWarningAlert(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.warningAlertOpen = p.warningAlertOpen ?? state.warningAlertOpen;
        }
    },
})

export const { setMouseState, setWarningAlert } = systemSlice.actions;
export default systemSlice.reducer;