import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { addUser } from "../../api/api";
import { useAppDispatch } from "../../store/hooks";
import { setAlertOpen, setSubmittedName } from "../../store/pageState";
import { useGetPageState } from "../../store/selectors/pageStateSelector";
import { useGetSettingsInfo } from "../../store/selectors/settingSelector";
import { setAnonymous, setUserName } from "../../store/settings";
import { NameState } from "../../utils/types";
import DoneIcon from '@mui/icons-material/Done';
import { useEffect, useState } from "react";
import theme from "../../utils/theme";

const EnterName = ({ disabled }: {disabled?: boolean} ): JSX.Element => {

    const dispatch = useAppDispatch();
    const settings = useGetSettingsInfo();
    const pageState = useGetPageState();
    const [hasUpdatedSoFar, setHasUpdatedSoFar] = useState<boolean>(false);
    const [bufferDone, setBufferDone] = useState<boolean>(true);

    // only show icons when user has updated their time
    useEffect(() => {
        if (pageState.currentlyUpdating) {
            setHasUpdatedSoFar(true);
            setBufferDone(false);
            setTimeout(() => {
                setBufferDone(true);
            }, 350);
        }
    }, [pageState.currentlyUpdating]);

    let actualName = settings.userName?.trim().substring(0, 15);
    if (settings.userName && settings.userName.length > 10) {
        actualName += "...";
    }

    const text = disabled 
        ? 'Group Availability' 
        : (!pageState.submittedName ? `Enter your name` : (`${!settings.isAnonymous ? `${actualName}'s` : `Your`} Availability`));

    const enterNameOrAnonymous = (isAnonymous: NameState) => {
        if (isAnonymous === NameState.HAS_NAME) {
            dispatch(setUserName({userName: settings.userName}));
        }
        dispatch(setSubmittedName({submittedName: true}));
        dispatch(setAnonymous({isAnonymous: isAnonymous === NameState.ANONYMOUS ? true : false}));

        if (settings.eventID === undefined || settings.userID === undefined) {
            return;
        }
        let userData = {
            eventID: settings.eventID,
            userID: settings.userID,
            isAnonymous: NameState.ANONYMOUS === isAnonymous,
        };
        if (NameState.HAS_NAME === isAnonymous) {
            userData = {...userData, ...{name: settings.userName}};
        }
        addUser(userData);
    }

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: pageState.submittedName ? 0 : 50,
                marginTop: 0,
            }}
        >
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 5, borderColor: theme.palette.primary.main}}>
                <Typography 
                    variant={text.length > 8 ? "h3" : "h2"}
                    fontStyle={'italic'} 
                    sx={{userSelect: 'none', mb: 10}}
                >{text}</Typography>
                {hasUpdatedSoFar && !disabled && (pageState.currentlyUpdating || !bufferDone ? <CircularProgress size={"1.5em"} sx={{ml: 20}}/> : <DoneIcon color="primary" sx={{ml: 20}}/>)}
            </Box>
            {
                !pageState.submittedName &&
                <Typography 
                    variant="h6" 
                    color={'primary'} 
                    sx={{
                        mb: 40,
                        userSelect: 'none',
                        '&:hover': {
                            cursor: 'pointer',
                        }
                    }}
                    onClick={() => {
                        enterNameOrAnonymous(NameState.ANONYMOUS);
                    }}
                >Stay Anonymous</Typography>
            }
            {
                !pageState.submittedName
                    &&  <Box sx={{display: 'flex', flexDirection: 'column', height: 150}}>
                            <TextField 
                                label="Your Name" 
                                variant="outlined" 
                                size="small"
                                sx={{mb: 15}}
                                inputProps={{style: {textAlign: 'center'}}}
                                onChange={(event) => {
                                    dispatch(setUserName({userName: (event.target.value as string).trim()}));
                                }}
                            />
                            <Button 
                                color={"primary"}
                                size="small"
                                variant={"outlined"}
                                sx={{width: 100, mb: 10, alignSelf: 'center', textTransform: 'none'}}
                                onClick={() => {
                                    if (settings.userName === undefined || settings.userName.trim().length === 0) {
                                        dispatch(setAlertOpen({emptyUserNameAlertOpen: true}));
                                    } else {
                                        enterNameOrAnonymous(NameState.HAS_NAME);
                                    }
                                }}
                            >
                                <Typography>
                                    Submit
                                </Typography>                                                        
                            </Button>
                        </Box>
                }
        </Box>
    )
}

export default EnterName;