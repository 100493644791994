import { Box, Typography, useMediaQuery } from "@mui/material";
import { useGetPageState } from "../../store/selectors/pageStateSelector";
import { useGetSettingsInfo } from "../../store/selectors/settingSelector";
import theme from "../../utils/theme";
import ColorLegend from "../ColorLegend";

const UsersResponded = (): JSX.Element => {

    const pageState = useGetPageState();
    const settings = useGetSettingsInfo();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const users: Set<string> = new Set();
    for (const cell of pageState.groupTimes as string[][]) {
        if (cell !== undefined) {
            for (const id of cell) {
                users.add(id);
            }
        }
    }
    const names: Array<string> = [];

    const title = users.size > 0 
        ? `${users.size} Response${users.size === 1 ? '' : 's'}`
        : 'No responses yet';

    let numAnonymous = 0;
    for (const userID of users) {
        for (const user of settings.usersResponded ?? []) {
            if (userID === user.userID && user.isAnonymous) {
                numAnonymous += 1;
                break;
            } else if (userID === user.userID && !user.isAnonymous && user.name !== undefined) {
                names.push(user.name);
                break;
            }
        }
    }
    const anonymousText = `${names.length > 0 ? '+' : ''}${numAnonymous} anonymous response${numAnonymous === 1 ? '' : 's'}`;

    return (
        <Box 
            sx={{display: 'flex', flexDirection: isMobile ? 'row' : 'column', alignItems: isMobile ? 'flex-start' : 'center', mt: isMobile ? 20 : 50, width: isMobile ? '85%': 'auto', justifyContent: isMobile ? 'space-between' : 'flex-start', paddingLeft: isMobile ? 10 : 0, paddingRight: isMobile ? 10 : 0, mb: isMobile ? 15 : 'inherit'}}
        >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '45%'}}>
                <Typography variant={isMobile ? "h6" : "h4"} fontStyle={"italic"} fontSize={isMobile ? 10 : 'inherit'}  sx={{mb: isMobile ? 5 : 10, userSelect: 'none', textAlign: 'center'}}>{title}</Typography>
                <Box
                    sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto', height: 100, minWidth: isMobile ? 100 : 200, padding: 10, borderRadius: 5, borderColor: theme.palette.secondary.main}}
                    border={4}
                >
                    {
                        names.map((name: string, index: number) => {
                            let finalName = `${name} ${settings.userName === name ? `(You)` : ``}`;
                            if (finalName.length > 15) {
                                finalName = finalName.substring(0, 15) + '...';
                            }

                            return <Typography key={index} sx={{userSelect: 'none'}} variant={finalName.length > 8 ? "h6" : finalName.length > 5 ? "h5" : "h4"} color={settings.userName === name ? theme.palette.primary.main : 'inherit'}>{finalName}</Typography>
                        })
                    }
                    {
                        numAnonymous > 0 && <Typography fontStyle={"italic"} variant={anonymousText.length > 8 ? "h6" : anonymousText.length > 5 ? "h5" : "h4"} fontSize={isMobile ? 10 : 'inherit'}  sx={{userSelect: 'none'}}>{anonymousText}</Typography>
                    }
                </Box>
            </Box>
            <ColorLegend />
        </Box>
    )
}

export default UsersResponded;