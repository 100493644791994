import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { useGetPageState } from "../../store/selectors/pageStateSelector";
import { ANIMATION_TIME } from "../../utils/constants";
import { getWindowDimensions } from "../../utils/functions";
import theme, { DARK_ORANGE, LIGHT_ORANGE } from "../../utils/theme";
import EnterName from "../EnterName";
import TimeTable from "../TimeTable";
import UsersResponded from "../UsersResponded";

const MobileTableViewer = (): JSX.Element => {

    const [isPersonal, setIsPersonal] = useState<boolean>(true);
    const pageState = useGetPageState();
    const animTime = ANIMATION_TIME/8;

    const [opacitySprings, api] = useSpring(() => ({
        from: { opacity: 0 },
    }));

    const fadeIn = () => {
        api.start({
            from: { opacity: opacitySprings.opacity },
            to: { opacity: 1 },
            config: {
                duration: animTime
            }
        })
    };

    const fadeOut = () => {
        api.start({
            from: { opacity: opacitySprings.opacity },
            to: { opacity: 0 },
            config: {
                duration: animTime
            }
        })
    }

    useEffect(() => {
        fadeIn();
    }, []);

    if (!pageState.submittedName) {
        return (
            <EnterName />
        )
    }

    // prevent scrolling on mobile
    document.body.style.overflow = "hidden";

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', mt: 20, mb: 400}}>
            <Box sx={{display: 'flex', flexDirection: 'row', width: '80%', justifyContent: 'space-between', mb: 20}}>
                <Typography 
                    onClick={() => {
                        fadeOut();
                        setTimeout(() => {
                            setIsPersonal(true);
                            fadeIn();
                        }, animTime);
                    }}
                    borderBottom={isPersonal ? 2 : 0}
                    sx={{
                        borderColor: DARK_ORANGE,
                        color: isPersonal ? 'black' : 'gray',
                    }}
                >Personal Availability</Typography>
                <Typography 
                    onClick={() => {
                        fadeOut();
                        setTimeout(() => {
                            setIsPersonal(false);
                            fadeIn();
                        }, animTime);
                    }}
                    borderBottom={!isPersonal ? 2 : 0}
                    sx={{
                        borderColor: DARK_ORANGE,
                        color: !isPersonal ? 'black' : 'gray',
                    }}
                >Group Availability</Typography>
            </Box>
            {
                isPersonal
                    ?   <animated.div style={{...opacitySprings}}><TimeTable /></animated.div>
                    :   <animated.div style={{...opacitySprings}}><TimeTable disabled={true}/></animated.div>
            }
            <Box sx={{width: '85%', height: 2, backgroundColor: theme.palette.primary.main, mt: 30}}></Box>
            <UsersResponded />
        </Box>
    )
}

export default MobileTableViewer;