/**
 * File for types, interfaces, and enums that can be used globally
 * 
 */

import { AlertColor } from "@mui/material"
import { Interval } from "./classes"

export enum AlertHideReason {
    TIMEOUT = "timeout",
    CLICK_AWAY = "clickaway",
    ESCAPE_KEY_DOWN= "escapeKeyDown",
}

export type EventDetails = {
    eventName: string,
    firstTime: number,
    lastTime: number,
    isDaysOfWeek: boolean,
    users?: Array<{userID: string, isAnonymous: boolean, name?: string}>,
    days?: Array<string>,
    dates?: Array<{dayOfWeek: number, month: number, dayOfMonth: number}>,
}

export type SnackBarAlert = {
    message: string,
    type: AlertColor,
    horizontal: 'left' | 'center' | 'right',
    vertical: 'top' | 'bottom',
    onClose: () => void,
}

export enum NameState {
    ANONYMOUS,
    HAS_NAME
}

export enum Day {
    SUN = "Sun", 
    MON = "Mon", 
    TUE = "Tue",
    WED = "Wed",
    THU = "Thu", 
    FRI = "Fri", 
    SAT = "Sat",
};

export type TimeDetails = {
    day: Day,
    startTime: number,
    userID: string,
}

export type AddUserParams = {
    eventID: string,
    userID: string,
    isAnonymous: boolean,
    name?: string,
}

export type UpdateTimeParams = {
    eventID: string,
    userID: string,
    times: Array<number>
}

export type WindowDimensions = {
    width: number | undefined,
    height: number | undefined,
}