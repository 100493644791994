import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TimeDetails } from '../../utils/types';

export type SliceState = {
    instructionsOpen?: boolean,  // the instructions are visible to the user
    createdEvent?: boolean,  // user has clicked on the create event button (could still be loading)
    waitingEvent?: boolean,  // user has clicked on the create event button, but is still loading
    copyAlertOpen?: boolean,  // user has just copied the event link
    createEventAlertOpen?: boolean, // user has just successfully created an event
    emptyEventNameAlertOpen?: boolean, // user has submitted an empty event name
    emptyEventNameAlertMessage?: string,  // why your event name was rejected
    emptyUserNameAlertOpen?: boolean, // user has submitted an empty user name
    submittedName?: boolean, // has the user submitted their name or has chosen to remain anonymous?
    loadingEvent?: boolean, // is the user loading the event from a url?
    joining?: boolean, // is the user joining from a url?
    groupTimes?: Array<Array<string>>, // fo fill in the 'group table'
    // groupTimes?: Array<TimeDetails>,  // to fill in the 'group table'
    currentlyUpdating?: boolean, // is the client waiting on the server's response?

    startingIndex?: number | undefined, // index of the cell that the user has started their drag with
    endingIndex?: number | undefined, // most recent index of the cell that the user has continued their drag with
    isAdding?: boolean | undefined,  // is the user adding or removing availability on this drag?
};

const initialState: SliceState = {
    instructionsOpen: false,
    waitingEvent: false,
    createdEvent: false,
    copyAlertOpen: false,
    createEventAlertOpen: false,
    submittedName: false,
    loadingEvent: false,
    joining: false,
    groupTimes: [],
    emptyEventNameAlertMessage: '',
    currentlyUpdating: false,
    startingIndex: undefined,
    endingIndex: undefined,
    isAdding: undefined,
};

const pageStateSlice = createSlice({
    name: 'pageState',
    initialState,
    reducers: {
        setInstructions(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.instructionsOpen = p.instructionsOpen ?? p.instructionsOpen;
        },
        setCreatedEvent(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.createdEvent = p.createdEvent ?? state.createdEvent;
            state.waitingEvent = p.waitingEvent ?? state.waitingEvent;
        },
        setAlertOpen(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.copyAlertOpen = p.copyAlertOpen ?? state.copyAlertOpen;
            state.createEventAlertOpen = p.createEventAlertOpen ?? state.createEventAlertOpen;
            state.emptyEventNameAlertOpen = p.emptyEventNameAlertOpen ?? state.emptyEventNameAlertOpen;
            state.emptyUserNameAlertOpen = p.emptyUserNameAlertOpen ?? state.emptyUserNameAlertOpen;
            state.emptyEventNameAlertMessage = p.emptyEventNameAlertMessage ?? state.emptyEventNameAlertMessage;
        },
        setSubmittedName(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.submittedName = p.submittedName ?? state.submittedName;
        },
        setLoadingEvent(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.loadingEvent = p.loadingEvent ?? state.loadingEvent;
        },
        setJoining(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.joining = p.joining ?? state.joining;
        },
        addGroupTime(state, action: PayloadAction<{index: number, userID: string}>) {
            const p = action.payload;
            if (state.groupTimes === undefined) return;
            const curr: Array<string> | undefined = state.groupTimes[p.index];
            if (curr === undefined) {
                state.groupTimes[p.index] = [p.userID];
            } else {
                state.groupTimes[p.index] = curr.filter((val: string) => val !== p.userID);
                state.groupTimes[p.index].push(p.userID);
            }
        },
        removeGroupTime(state, action: PayloadAction<{index: number, userID: string}>) {
            const p = action.payload;
            if (state.groupTimes === undefined) return;
            const curr: Array<string> | undefined = state.groupTimes[p.index];
            if (curr !== undefined) {
                state.groupTimes[p.index] = curr.filter((time) => time !== p.userID);
            }
        },
        currentlyUpdatingTimes(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.currentlyUpdating = p.currentlyUpdating ?? state.currentlyUpdating;
        },
        setIndices(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.startingIndex = p.startingIndex;
            state.endingIndex = p.endingIndex;
        },
        setIsAdding(state, action: PayloadAction<boolean | undefined>) {
            const p = action.payload;
            state.isAdding = p;
        }
    },
})

export const { setInstructions, setCreatedEvent, setAlertOpen, setSubmittedName, setLoadingEvent, setJoining, addGroupTime, removeGroupTime, currentlyUpdatingTimes, setIndices, setIsAdding } = pageStateSlice.actions;
export default pageStateSlice.reducer;