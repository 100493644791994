import { Box, Button, CircularProgress, Fade, Tab, Tabs, TextField, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Slide from '@mui/material/Slide';
import TimeTable from "../../components/TimeTable";
import { useAppDispatch } from "../../store/hooks";
import { setMouseState } from "../../store/system";
import { addTimesUpdating, addUsersResponded, resetTimesUpdating, setAnonymous, setCalendarDates, setDays, setDaysOfWeek, setEventID, setEventName as setEventNameRedux, setTimes, setUserID, setUserName as setUserNameRedux, setUserTimes } from "../../store/settings";
import TimeChoosers from "../../components/TimeChoosers";
import { useGetSettingsInfo } from "../../store/selectors/settingSelector";
import { createEvent, getEvent, getTimes, updateTime } from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import { Day, EventDetails, WindowDimensions } from "../../utils/types";
import SnackBarAlert from "../../components/SnackBarAlert/SnackBarAlert";
import { LoadingButton, TabPanel } from "@mui/lab";
import HeaderText from "../../components/HeaderText";
import { useGetPageState } from "../../store/selectors/pageStateSelector";
import { addGroupTime, currentlyUpdatingTimes, removeGroupTime, setAlertOpen, setCreatedEvent, setIndices, setInstructions, setIsAdding, setJoining, setLoadingEvent, setSubmittedName } from "../../store/pageState";
import { ANIMATION_TIME } from "../../utils/constants";
import { useSpring, animated } from "react-spring";
import EnterName from "../../components/EnterName";
import { calculateNewTimes, getIndicesFromPositions, getTimeMapsFromObject, getUserTimesFromMap, getWindowDimensions, isValidDates, isValidEventName } from "../../utils/functions";
import { v4 as uuidv4 } from 'uuid';
import { HALF_HOUR } from "../../components/TimeChoosers/TimeChoosers";
import UsersResponded from "../../components/UsersResponded";
import DateSelect from "../../components/DateSelect";
import theme, { DARK_ORANGE, LIGHT_ORANGE } from "../../utils/theme";
import MobileTableViewer from "../../components/MobileTableViewer";

const Home = (): JSX.Element => {
    const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(getWindowDimensions());
    const [fadeDone, setFadeDone] = useState<boolean>(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const settings = useGetSettingsInfo();
    const pageState = useGetPageState();
    const params = useParams();

    const windowWidthRef = useRef(windowDimensions.width);
    const windowHeightRef = useRef(windowDimensions.height);
    const submittedNameRef = useRef(pageState.submittedName);

    const [opacitySprings, api] = useSpring(() => ({
        from: { opacity: 0 },
    }));

    const fadeIn = () => {
        api.start({
            from: { opacity: 0 },
            to: { opacity: 1 },
        })
    };

    useEffect(() => {
        fadeIn();
    }, []);

    useEffect(() => {
        windowWidthRef.current = windowDimensions.width;
        windowHeightRef.current = windowDimensions.height;
    }, [windowDimensions]);

    useEffect(() => {
        submittedNameRef.current = pageState.submittedName;
    }, [pageState.submittedName]);

    //get user ID or set one if it doesnt exist
    useEffect(() => {
        const userID: string | null = localStorage.getItem('userID');
        let newUserID: string = '';
        if (userID === null) {
            newUserID = uuidv4();
            localStorage.setItem('userID', newUserID);
        }
        dispatch(setUserID({userID: userID !== null ? userID : newUserID}));
    }, []);

    // get event if eventID in path params
    useEffect(() => {
        const getEvents = async () => {
            const eventID: string | undefined = params.eventID;
            if (eventID !== undefined) {
                dispatch(setJoining({joining: true}));
                dispatch(setLoadingEvent({loadingEvent: true}));

                const eventDetails: EventDetails | undefined = await getEvent(eventID);
                if (eventDetails !== undefined) {
                    onJoiningLoad(eventDetails, eventID);
                } else {
                    // TODO: Route back to regular home OR give a 404 page
                    navigate(`/notfound`, { replace: true });
                }
            }
            return undefined;
        }

        try { 
            getEvents();
        } catch (err) { 
            console.log(err);
        }
    }, []);

    /**
     * when user clicks on create event button
     */
    const onCreateEventClick = async (): Promise<void> => {
        dispatch(setCreatedEvent({createdEvent: true, waitingEvent: true}));

        const dayMap = new Map([
            [0, 'Sun'],
            [1, 'Mon'],
            [2, 'Tue'],
            [3, 'Wed'],
            [4, 'Thu'],
            [5, 'Fri'],
            [6, 'Sat'],
        ]);

        const days = settings.days?.map((val: boolean, index: number) => val ? dayMap.get(index) as string : undefined).filter((val: string | undefined) => val !== undefined) as string[] | undefined;

        const eventDetails: EventDetails = {
            eventName: settings.eventName as string,
            firstTime: settings.firstTime as number,
            lastTime: settings.lastTime as number,
            isDaysOfWeek: settings.daysOfWeek as boolean,
            days: settings.daysOfWeek ? days : undefined,
            dates: !settings.daysOfWeek ? settings.calendarDates : undefined,
        }
        const eventID: string | void = await createEvent(eventDetails);
        if (typeof eventID === 'string') {
            dispatch(setEventID({ eventID }));
            dispatch(setCreatedEvent({waitingEvent: false}));
            dispatch(setAlertOpen({createEventAlertOpen: true}));
            navigate(`/${eventID}`);
        }
        handleFade();
    }

    /**
     * when user joins from url and finished loading event details
     */
    const onJoiningLoad = async (eventDetails: EventDetails, eventID: string): Promise<void> => {
        // update state
        const existingID: string | null = localStorage.getItem("userID");
        if (eventDetails.users !== undefined) {
            for (const user of eventDetails.users) {
                if (user.userID === existingID) {
                    dispatch(setAnonymous({isAnonymous: user.isAnonymous}));
                    if (user.name !== undefined) {
                        dispatch(setUserNameRedux({userName: user.name, userID: existingID}));
                    }
                    dispatch(setSubmittedName({submittedName: true}));
                }
            }
        }

        // get current users
        const users = eventDetails.users;
        if (users !== undefined) {
            for (const {userID, isAnonymous, name} of users) {
                dispatch(addUsersResponded({userID, isAnonymous, name}));
            }
        }

        // get times
        const times = await getTimes(eventID);
        if (times) {
            for (const [userID, indices] of Object.entries(times)) {
                if (userID !== 'eventID') {
                    for (const index of indices as number[]) {
                        dispatch(addGroupTime({index, userID}));
                    }
                }
            }
        }

        dispatch(setLoadingEvent({loadingEvent: false}));
        dispatch(setEventID({eventID}));
        dispatch(setEventNameRedux({
            eventName: eventDetails.eventName, 
        }));
        dispatch(setTimes({
            firstTime: eventDetails.firstTime, 
            lastTime: eventDetails.lastTime
        }));
        dispatch(setDaysOfWeek({daysOfWeek: eventDetails.isDaysOfWeek}));
        const days = [Day.SUN, Day.MON, Day.TUE, Day.WED, Day.THU, Day.FRI, Day.SAT];
        if (eventDetails.isDaysOfWeek) {
            dispatch(setDays({days: days.map((day: Day) => eventDetails.days?.includes(day)) as boolean[]}));
        } else {
            dispatch(setCalendarDates({calendarDates: eventDetails.dates}));
        }
        handleFade();
    }

    const handleFade = () => {
        setTimeout(() => {
            setFadeDone(true);
        }, ANIMATION_TIME);
    }

    const renderAlertIfNeeded: JSX.Element | null = 
        pageState.createEventAlertOpen 
            ?   <SnackBarAlert message={"Successfully created event!"}type={"success"} horizontal={"center"} vertical={"bottom"} onClose={() => dispatch(setAlertOpen({createEventAlertOpen: false}))} />
            :   null;

    const renderCopySnackBarIfNeeded: JSX.Element | null = 
        pageState.copyAlertOpen
            ?   <SnackBarAlert message={"Copied to clipboard!"} type={"success"} horizontal={"left"} vertical={"top"} onClose={() => dispatch(setAlertOpen({copyAlertOpen: false}))} />
            :   null;

    const renderEmptyEventNameAlertIfNeeded: JSX.Element | null =
        pageState.emptyEventNameAlertOpen && pageState.emptyEventNameAlertMessage
            ?   <SnackBarAlert message={pageState.emptyEventNameAlertMessage} type={"warning"} horizontal={"left"} vertical={"top"} onClose={() => dispatch(setAlertOpen({emptyEventNameAlertOpen: false}))} />
            :   null;

    const renderEmptyUserNameAlertIfNeeded: JSX.Element | null =
        pageState.emptyUserNameAlertOpen 
            ?   <SnackBarAlert message={"Your name cannot be empty!"} type={"warning"} horizontal={"center"} vertical={"bottom"} onClose={() => dispatch(setAlertOpen({emptyUserNameAlertOpen: false}))} />
            :   null;

    const renderNameBoxIfNeeded: JSX.Element | null =
        !fadeDone && !pageState.joining
            ?   <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <TextField 
                        label="Event Name" 
                        variant="outlined" 
                        inputProps={{style: {textAlign: 'center', backgroundColor: 'white'}}}
                        onChange={(event) => {
                            const newEventName: string = event.target.value;
                            dispatch(setEventNameRedux({eventName: newEventName.trim()}));
                        }}
                    />
                </Box>
            :   null;

    const renderTimesIfNeeded: JSX.Element | null =
        !fadeDone && !pageState.joining
            ?   <Box sx={{mt: 20}}>
                    <TimeChoosers />
                </Box>
            :   null;

    const renderDateSelectIfNeeded: JSX.Element | null =
        !fadeDone && !pageState.joining
            ?   <DateSelect/> 
            :   null;

    // special case
    if (pageState.loadingEvent) {
        return (
            <Box sx={{height: '100vh', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', position: 'relative'}}>
                <Typography variant="h2" sx={{mb: 150}} fontStyle="italic">Loading Event</Typography>
                <Box component={'img'} src={require('../../assets/images/logo.png')} sx={{width: 50, height: 50, position: 'absolute'}} />
                <CircularProgress color="primary" size={'3em'} sx={{position: 'absolute'}}/>
            </Box>
        )
    }

    const release = async () => {
        dispatch(setMouseState({mouseDown: false}));
        const indices = getIndicesFromPositions(pageState.startingIndex as number, pageState.endingIndex as number, (settings.lastTime as number - (settings.firstTime as number))*2);
        if (indices.length > 0) {
            dispatch(currentlyUpdatingTimes({currentlyUpdating: true}));

            if (!settings.usersResponded?.includes({
                userID: settings.userID as string,
                isAnonymous: settings.isAnonymous as boolean,
                name: settings.userName,
            })) {
                dispatch(addUsersResponded({
                    userID: settings.userID as string,
                    isAnonymous: settings.isAnonymous as boolean,
                    name: settings.userName,
                }));
            }
        }
        for (const index of indices) {
            if (pageState.isAdding) {
                dispatch(addGroupTime({index, userID: settings.userID as string}));
            } else if (pageState.isAdding === false) {
                dispatch(removeGroupTime({index, userID: settings.userID as string}));
            }
        }
        dispatch(setIsAdding(undefined));
        dispatch(setIndices({startingIndex: undefined, endingIndex: undefined}));

        const userTimes: Set<number> = pageState.isAdding ? new Set(indices) : new Set();
        let index = 0;
        for (const cell of pageState.groupTimes as string[][]) {
            if (cell !== undefined && !(pageState.isAdding === false && indices.includes(index))) {
                for (const id of cell) {
                    if (id === settings.userID) {
                        userTimes.add(index);
                    }
                }
            }
            index += 1;
        }
        if (userTimes.size > 0) {
            await updateTime({
                eventID: settings.eventID as string,
                userID: settings.userID as string,
                times: [...userTimes],
            });
        }
        dispatch(currentlyUpdatingTimes({currentlyUpdating: false}));
    }

    const press = (event: any) => {
        if (pageState.submittedName && !isMobile) {
            event.preventDefault();
        }
        dispatch(setMouseState({mouseDown: true}));
    }

    return (
        <animated.div
            style={{
                ...opacitySprings,
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center', 
                height: '100vh',
            }}
            onTouchStart={(event) => {
                press(event);
            }}
            onTouchMove={(event) => {
                const touch = event.touches[0];
                const element: Element | null = document.elementFromPoint(touch.clientX, touch.clientY);
                if (element !== null) {
                    const id: string = element.id;
                    const index: number = Number.parseInt(id.split('-')[0]);
                    const personal: boolean = id.split('-')[1] === 'personal';
                    const isSelected: boolean = pageState.groupTimes ? (pageState.groupTimes[index]?.includes(settings.userID as string) ?? false) : false;
                    if (!personal) return;
                    if (pageState.isAdding === undefined) {
                        if (isSelected) {
                            dispatch(setIsAdding(false));
                        } else {
                            dispatch(setIsAdding(true));
                        }
                    }
                    if (pageState.startingIndex === undefined) {
                        dispatch(setIndices({
                            startingIndex: index,
                            endingIndex: index,
                        }));
                    } else {
                        dispatch(setIndices({
                            startingIndex: pageState.startingIndex,
                            endingIndex: index,
                        }));
                    }
                }
            }}
            onTouchEnd={() => {
                release();
            }}
            onMouseDown={(event) => {
                if (!isMobile) {
                    press(event);
                }
            }}
            onMouseUp={async () => {
                if (!isMobile) {
                    release();
                }
            }}
        >
            <HeaderText/>
            {
                fadeDone
                    ?   <Fade in={fadeDone} timeout={ANIMATION_TIME}>
                            <Box 
                                sx={{
                                    flex: 5, 
                                    display: 'flex', 
                                    width: '100%', 
                                    alignItems: isMobile ? 'center' : 'flex-start', 
                                    flexDirection: isMobile ? 'column' : 'row', 
                                    justifyContent: isMobile ? 'flex-start' : 'center', 
                                }}
                            >
                                {
                                    isMobile
                                        ?   <MobileTableViewer />
                                        :   <>
                                                <Box sx={{display: 'flex', justifyContent: 'flex-start', paddingLeft: 20, paddingRight: 20, position: 'relative', transform: `translate(0px, -${pageState.submittedName ? 140 : 0}px)`, flexDirection: 'column' }}>
                                                    <EnterName />
                                                    <TimeTable />
                                                </Box>
                                                {
                                                    pageState.submittedName && <UsersResponded />
                                                }
                                                {
                                                    pageState.submittedName && 
                                                    <Box sx={{display: 'flex', position: 'relative', transform: `translate(0px, -${pageState.submittedName ? 140 : 0}px)`, paddingLeft: 20, paddingRight: 20, flexDirection: 'column' }}>
                                                        <EnterName disabled={true}/>
                                                        <TimeTable disabled={true} />
                                                    </Box>
                                                }
                                            </>
                                }
                            </Box>  
                        </Fade>   
                    :   <Box sx={{flex: 2.5, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <Fade 
                                in={!pageState.createdEvent || pageState.waitingEvent && !pageState.joining} 
                                timeout={ANIMATION_TIME}
                                appear={false}
                            >
                                <Box sx={{flex: 1.4, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
                    
                                    <Box sx={{display: 'flex', borderRadius: 5, flexDirection: 'column', justifyContent: 'center', p: 20, width: '50%', mb: 10, alignItems: 'center'}}>
                                        {renderNameBoxIfNeeded}
                                        {renderTimesIfNeeded}
                                    </Box>

                                    {renderDateSelectIfNeeded}

                                    {!pageState.joining &&
                                        <Box
                                            border={1}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: 200, 
                                                height: 50,
                                                alignSelf: 'center', 
                                                textTransform: 'none', 
                                                borderColor: DARK_ORANGE, 
                                                mt: 10,
                                                mb: isMobile ? 40 : 0, 
                                                borderRadius: 5, 
                                                backgroundColor: 'white',
                                                '&:hover': {
                                                    backgroundColor: pageState.waitingEvent ? 'inherit' : LIGHT_ORANGE,
                                                    cursor: pageState.waitingEvent ? 'default' : 'pointer',
                                                }
                                            }}
                                            onClick={() => {
                                                if (!pageState.waitingEvent) {
                                                    const nameRes: boolean | string = isValidEventName(settings.eventName?.trim());
                                                    const datesRes: boolean | string = isValidDates(settings);
    
                                                    if (nameRes !== true) {
                                                        dispatch(setAlertOpen({emptyEventNameAlertOpen: true, emptyEventNameAlertMessage: nameRes}));
                                                    } else if (datesRes !== true) {
                                                        dispatch(setAlertOpen({emptyEventNameAlertOpen: true, emptyEventNameAlertMessage: datesRes}));
                                                    } else {
                                                        onCreateEventClick();
                                                    }
                                                }
                                            }}
                                        >
                                            <Typography sx={{color: DARK_ORANGE}}>
                                                {pageState.waitingEvent ? <CircularProgress size="1.5em" color={'inherit'} /> : `Create Event`}
                                             </Typography>
                                        </Box>
                                    }
                                </Box>  
                            </Fade>   
                        </Box>
            }          
            {renderAlertIfNeeded}
            {renderCopySnackBarIfNeeded}
            {renderEmptyEventNameAlertIfNeeded}
            {renderEmptyUserNameAlertIfNeeded}
        </animated.div>
    )
}

export default Home;