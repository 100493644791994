import { Theme } from "@mui/material";
import { createTheme } from '@mui/material/styles';

const BLUE = '#7CC4F7';
const MIDDLE_BLUE = '#d9f5ff';
const LIGHT_BLUE = '#F7FDFF';
const BLACK = '#000000';
const WHITE = '#FFFFFF';
export const DARK_ORANGE = '#ffb300';
export const LIGHT_ORANGE = '#fffaf0';

const theme: Theme = createTheme({
    spacing: (value: string | number) => value,
    palette: {
        primary: {
            main: BLUE,
            light: LIGHT_BLUE,
        },
        secondary: {
            main: MIDDLE_BLUE,
        },
        background: {
            default: WHITE
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                style: {
                    backgroundColor: LIGHT_BLUE,
                    minWidth: 0,
                    minHeight: 0,
                },
                disableRipple: true,
            }
        }
    },
    typography: {
        allVariants: {},
        fontFamily: ['Actor'].join(","),
        h1: { fontSize: 50 },
        h2: { fontSize: 40 },
        h3: { fontSize: 30 },
        h4: { fontSize: 20 },
        h5: { fontSize: 18 },
        h6: { fontSize: 15 },
    },
});

export default theme;