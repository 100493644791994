import { Alert, Slide, Snackbar } from '@mui/material';
import { useState } from 'react';
import { SNACK_BAR_TIME } from '../../utils/constants';
import { AlertHideReason, SnackBarAlert as PropType } from '../../utils/types';

const SnackBarAlert = ({message, type, horizontal, vertical, onClose}: PropType): JSX.Element => {
    const [open, setOpen] = useState<boolean>(true);
    const bufferTime = 500;  // allow the slide out transition to occur
    
    return (
        <Snackbar 
            open={open} 
            autoHideDuration={SNACK_BAR_TIME} 
            onClose={(_event, reason) => {
                if (reason === AlertHideReason.TIMEOUT) {
                    setOpen(false);
                    setTimeout(() => {
                        onClose();
                    }, bufferTime);
                }
            }}
            TransitionComponent={Slide}
            anchorOrigin={{vertical, horizontal}}
        >
            <Alert 
                onClose={() => {
                    setOpen(false);
                    setTimeout(() => {
                        onClose();
                    }, bufferTime);
                }} 
                severity={type} 
                sx={{userSelect: 'none'}}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

export default SnackBarAlert;