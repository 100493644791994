const local = {
    name: 'local',
    url: {
        EVENTS_URL: "https://c1ps8vf6n9.execute-api.us-east-1.amazonaws.com",
        TIMES_URL: "https://amis49j9dj.execute-api.us-east-1.amazonaws.com",
    }
}

const dev = {
    name: 'dev',
    url: {
        EVENTS_URL: "https://qp4dt4w90g.execute-api.us-east-1.amazonaws.com",
        TIMES_URL: "https://s74d7b6vf9.execute-api.us-east-1.amazonaws.com",
    }
}

const prod = {
    name: 'prod',
    url: {
        EVENTS_URL: "https://qp4dt4w90g.execute-api.us-east-1.amazonaws.com",
        TIMES_URL: "https://s74d7b6vf9.execute-api.us-east-1.amazonaws.com",
    }
}

export const config = process.env.REACT_APP_ENV === 'prod' ? prod : (process.env.REACT_APP_ENV === 'dev' ? dev : local);