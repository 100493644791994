import { Box, Checkbox, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import theme, { DARK_ORANGE, LIGHT_ORANGE } from "../../utils/theme";
import DoneIcon from '@mui/icons-material/Done';
import Calendar from "../Calendar";
import { useAppDispatch } from "../../store/hooks";
import { setDays, setDaysOfWeek } from "../../store/settings";
import { animated, useSpring } from "react-spring";
import { ANIMATION_TIME } from "../../utils/constants";

const DateSelect = (): JSX.Element => {
    const [chosen, setChosen] = useState<number>(0);
    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [checked, setChecked] = useState<Array<boolean>>([false, true, true, true, true, true, false]);

    const horizontalPadding = isMobile ? 20 : 0;
    const animTime = ANIMATION_TIME/8;

    const [daysOpacity, daysApi] = useSpring(() => ({
        from: { opacity: 1 },
    }));

    const daysFadeIn = () => {
        daysApi.start({
            from: { opacity: daysOpacity.opacity },
            to: { opacity: 1 },
            config: {
                duration: animTime
            }
        })
    };

    const daysFadeOut = () => {
        daysApi.start({
            from: { opacity: daysOpacity.opacity },
            to: { opacity: 0.3 },
            config: {
                duration: animTime
            }
        })
    }

    const [calendarOpacity, calendarApi] = useSpring(() => ({
        from: { opacity: 0.3 },
    }));

    const calendarFadeIn = () => {
        calendarApi.start({
            from: { opacity: calendarOpacity.opacity },
            to: { opacity: 1 },
            config: {
                duration: animTime
            }
        })
    };

    const calendarFadeOut = () => {
        calendarApi.start({
            from: { opacity: calendarOpacity.opacity },
            to: { opacity: 0.3 },
            config: {
                duration: animTime
            }
        })
    }

    return (
        <Box sx={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', mb: 20, maxHeight: isMobile ? 400 : 275, overflow: 'scroll'}}>
            <animated.div style={{...daysOpacity}}>
                <Box 
                    border={1} 
                    sx={{flex: 1, position: 'relative', mr: isMobile ? 0 : 20, height: '100%', minWidth: isMobile ? 300 : 400, display: 'flex', flexDirection: 'column', alignItems: 'center', borderColor: chosen === 0 ? theme.palette.primary.main: 'gray', borderRadius: 5, padding: 20, backgroundColor: chosen === 0 ? theme.palette.secondary.light : 'inherit', '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: chosen === 1 ? theme.palette.secondary.main : theme.palette.secondary.light,
                    }}}
                    onClick={() => {
                        setChosen(0);
                        daysFadeIn();
                        calendarFadeOut();
                        dispatch(setDaysOfWeek({daysOfWeek: true}));
                    }}
                >
                    {/* <Typography fontSize={10} fontStyle="italic" sx={{userSelect: 'none'}}>schedule by</Typography> */}
                    <Typography fontSize={25} sx={{userSelect: 'none', mb: 5}} fontStyle="initial">{`Days of Week`}</Typography>
                    {daysOfWeek.map((day: string, index: number) => {
                        return (
                            <Box key={day + index} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '75%'}}>
                                <Typography variant="h6" sx={{userSelect: 'none'}}>{day}</Typography>
                                <Checkbox checked={checked[index] === true} size="small" sx={{padding: 0}} onChange={(e) => {
                                    const newChecked = [...checked];
                                    newChecked[index] = !newChecked[index];
                                    dispatch(setDays({days: newChecked}));
                                    setChecked(newChecked);
                                }} />
                            </Box>
                        )
                    })}
                </Box>
            </animated.div>
            <Typography sx={{userSelect: 'none'}} fontStyle={"italic"}>OR</Typography>
            <animated.div style={{...calendarOpacity, height: '100%'}}>
                <Box 
                    border={1} 
                    sx={{flex: 1, ml: isMobile ? 0 : 20, position: 'relative', height: '100%', minWidth: isMobile ? 300 : 400, display: 'flex', flexDirection: 'column', alignItems: 'center', borderColor: chosen === 1 ? theme.palette.primary.main : 'gray', borderRadius: 5, padding: 20, backgroundColor: chosen === 1 ? theme.palette.secondary.light : 'inherit', '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: chosen === 0 ? theme.palette.secondary.main : theme.palette.secondary.light,
                    }}}
                    onClick={() => {
                        setChosen(1);
                        daysFadeOut();
                        calendarFadeIn();
                        dispatch(setDaysOfWeek({daysOfWeek: false}));
                    }}
                >
                    {/* <Typography fontSize={10} fontStyle="italic" sx={{userSelect: 'none'}}>schedule by</Typography> */}
                    <Typography fontSize={25} sx={{userSelect: 'none', mb: 50}} fontStyle="initial">{`Calendar Dates`}</Typography>
                    <Calendar />
                </Box>
            </animated.div>
        </Box>
    )
}

export default DateSelect;