import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "@fontsource/actor";

//theme
import "primereact/resources/themes/saga-blue/theme.css";   

// import "primereact/resources/themes/arya-orange/theme.css";
    
//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";                                         
        

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
