import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Day } from '../../utils/types';

export type SliceState = {
    firstTime?: number | undefined,
    lastTime?: number | undefined,
    timeZone?: string | undefined,

    eventName?: string | undefined,
    eventID?: string | undefined,

    userName?: string | undefined,
    userID?: string | undefined,
    isAnonymous?: boolean,

    daysOfWeek?: boolean,  // user is using daysOfWeek rather than Calendar Dates
    days?: Array<boolean>,  // which days is the user using in order [Sun, M, T, W, Th, F, Sat]
    calendarDates?: Array<{dayOfWeek: number, month: number, dayOfMonth: number}>,  // which dates is the user using from the calendar?

    timesUpdating?: Array<boolean>, // mouse is down and selecting/deselecting items

    usersResponded?: {
        userID: string,
        isAnonymous: boolean,
        name?: string,
    }[],  // who has already filled out their availability?
    userTimes?: {
        day: Day,
        time: number,
    }[],  // when is the user available? Updated only when user releases click
}

const initialState: SliceState = {
    firstTime: undefined,
    lastTime: undefined,
    timeZone: 'EST',
    eventName: undefined,
    userName: undefined,
    eventID: undefined,
    isAnonymous: false,
    userID: undefined,
    timesUpdating: [],
    usersResponded: [],
    userTimes: [],
    daysOfWeek: true,
    days: [false, true, true, true, true, true, false],
    calendarDates: [],
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setTimes(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.firstTime = p.firstTime ?? state.firstTime;
            state.lastTime = p.lastTime ?? state.lastTime;
            state.timeZone = p.timeZone ?? state.timeZone;
        },
        setEventName(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.eventName = p.eventName ?? state.eventName;
        },
        setUserName(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.userName = p.userName ?? state.userName;
        },
        setEventID(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.eventID = p.eventID ?? state.eventID;
        },
        setAnonymous(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.isAnonymous = p.isAnonymous ?? state.isAnonymous;
        },
        setUserID(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.userID = p.userID ?? state.userID;
        },
        addTimesUpdating(state, action: PayloadAction<number>) {
            const p = action.payload;
            if (state.timesUpdating === undefined) return;  
            state.timesUpdating[p] = true;
        },
        removeTimesUpdating(state, action: PayloadAction<number>) {
            const p = action.payload;
            if (state.timesUpdating === undefined) return;
            state.timesUpdating[p] = false;
        },
        resetTimesUpdating(state, _action: PayloadAction<void>) {
            state.timesUpdating = [];
        },
        addUsersResponded(state, action: PayloadAction<{isAnonymous: boolean, userID: string, name?: string}>) {
            state.usersResponded?.push(action.payload);
        },
        setUserTimes(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.userTimes = p.userTimes ?? state.userTimes;
        },
        setDaysOfWeek(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.daysOfWeek = p.daysOfWeek;
        },
        setDays(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.days = p.days;
        },
        setCalendarDates(state, action: PayloadAction<SliceState>) {
            const p = action.payload;
            state.calendarDates = p.calendarDates;
        }
    },
})

export const { setTimes, setEventName, setUserName, setEventID, setAnonymous, setUserID, addTimesUpdating, removeTimesUpdating, resetTimesUpdating, addUsersResponded, setUserTimes, setDaysOfWeek, setDays, setCalendarDates } = settingsSlice.actions;
export default settingsSlice.reducer;