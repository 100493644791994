import { combineReducers } from '@reduxjs/toolkit';

import systemReducer from './system';
import settingsReducer from './settings';
import pageStateReducer from './pageState';

const rootReducer = combineReducers({
    system: systemReducer,
    settings: settingsReducer,
    pageState: pageStateReducer,
});

export default rootReducer;