import { Box, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetPageState } from "../../store/selectors/pageStateSelector";
import { useGetSettingsInfo } from "../../store/selectors/settingSelector";
import { getWindowDimensions, indexToCoords } from "../../utils/functions";
import { HalfDay, HALF_HOUR, NOON } from "../TimeChoosers/TimeChoosers";
import Section from "./Section";
import { Day, WindowDimensions } from "../../utils/types";
import theme from "../../utils/theme";


const TimeTable = ({ disabled }: {disabled?: boolean}) => {
    const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(getWindowDimensions());
    const settings = useGetSettingsInfo();
    const pageState = useGetPageState();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const hasWindow: boolean = typeof window !== undefined;

    // constants
    const daysOfWeek: Day[] = [Day.SUN, Day.MON, Day.TUE, Day.WED, Day.THU, Day.FRI, Day.SAT];
    const filteredDays: Day[] = daysOfWeek.filter((day: Day, index: number) => settings.days && settings.days[index] === true);
    const sortedCalendarDates = settings.calendarDates ? [...settings.calendarDates].sort((a, b) => ((a.month - b.month)*100 + (a.dayOfMonth - b.dayOfMonth))) : undefined;
    const containerBorderWidth = 0;
    const sectionMargin = 1;
    const numDays = (settings.daysOfWeek ? settings.days?.filter((day) => day).length : settings.calendarDates?.length) ?? 7;
    const numTimes = settings.firstTime === undefined || settings.lastTime === undefined
        ?   12
        :   (settings.lastTime - settings.firstTime)*2;
    const numRows = numTimes + 1;
    const numCols = numDays + 1;

    // before making integer and evenly divisible
    const preliminaryWidth = windowDimensions.width === undefined
        ?   undefined
        :   Math.max(Math.floor(isMobile ? windowDimensions.width*3/4 : windowDimensions.width/4), isMobile ? 100 : 500);

    const preliminaryHeight = windowDimensions.height === undefined
        ?   undefined
        :   Math.max(Math.floor(windowDimensions.height/2), isMobile ? 100 : 500);

    // after making evenly divisible
    const containerWidth = preliminaryWidth === undefined 
        ? undefined 
        : preliminaryWidth - (preliminaryWidth - (numCols+1)*sectionMargin)%numDays;
    const containerHeight = preliminaryHeight === undefined 
        ? undefined 
        : preliminaryHeight - (preliminaryHeight - (numRows+1)*sectionMargin)%numTimes;

    const width = ((containerWidth as number) - (numCols+1)*sectionMargin)/numCols;
    const height = ((containerHeight as number) - (numRows+1)*sectionMargin)/numRows;

    // user drag
    const first = indexToCoords(pageState.startingIndex as number, numTimes);
    const second = indexToCoords(pageState.endingIndex as number, numTimes);
    const leftMost = Math.min(first.col, second.col);
    const rightMost = Math.max(first.col, second.col);
    const bottomMost = Math.max(first.row, second.row);
    const topMost = Math.min(first.row, second.row);

    // when user resizes window, get updated value
    const handleWindowResize = () => {
        setWindowDimensions(getWindowDimensions());
    };

    // window resize subscriber
    useEffect(() => {
        if (hasWindow) {    
          window.addEventListener('resize', handleWindowResize);
          return () => window.removeEventListener('resize', handleWindowResize);
        }
    }, [hasWindow]);

    // draw the squares that make up the time
    const renderSections: () => JSX.Element[] | null = () => {

        if (containerWidth === undefined || containerHeight === undefined) {
            return null;
        }

        const sections: JSX.Element[] = [];
        let sectionIndex = 0;
        let calendarIndex = 0;
        for (let i = 0; i < (numCols)*(numRows); i++) {
            const marginLeft = sectionMargin;
            const marginRight = Math.floor((i+1)/numRows) == numCols ? sectionMargin : 0;
            const marginTop = sectionMargin;
            const marginBottom = (i+1) % numRows === 0 ? sectionMargin : 0;

            const skip = numTimes <= 24 ? 2 : 4;

            if (i < numRows && settings.firstTime !== undefined) {
                // rendering the times 
                const preliminaryTimeNumber = Math.floor(settings.firstTime + (i)*HALF_HOUR);
                const timeNumber = preliminaryTimeNumber % 12;
                const wholeHour: boolean = ((i) % 2 === 0 && settings.firstTime % 1 === 0) || (settings.firstTime % 1 !== 0 && (i) % 2 === 1);
                let time = `${timeNumber === 0 ? 12 : timeNumber}${wholeHour ? '' : ':30'}`;
                let halfDay: HalfDay = preliminaryTimeNumber >= NOON && preliminaryTimeNumber !== 24 ? 'pm' : 'am';
                let toDisplay: string = (i) % skip === 0 ? `${time} ${halfDay}` + ' -' : '';
                let bottomOffset = -height/2;

                const timeBox = 
                    <Box
                        key={`timeBox-${i}`}  
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            marginTop, 
                            marginBottom, 
                            marginRight, 
                            marginLeft,
                            width: width,
                            height,
                            userSelect: 'none',
                        }}  
                    >
                        <Typography 
                            fontSize={isMobile ? 8 : (width < 50 ? 12 : 16)}
                            sx={{
                                position: 'relative',
                                bottom: bottomOffset,
                            }}
                        >
                            {toDisplay}
                        </Typography>
                    </Box>
                sections.push(timeBox);
            } else if (i > 0 && i % numRows === 0) {
                // rendering days of the week
                if (sortedCalendarDates === undefined) continue;

                const dayBox = 
                    <Box
                        key={`dayBox-${i}`}  
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop, 
                            marginBottom, 
                            marginRight, 
                            marginLeft,
                            width,
                            height: height,
                            userSelect: 'none',
                        }}  
                    >
                        {
                            settings.daysOfWeek
                                ?   <Typography 
                                        variant="h6"
                                    >
                                        {filteredDays[i/numRows - 1]}
                                    </Typography>
                                :   <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', flex: 1}}>
                                        <Typography 
                                            fontSize={10}
                                        >
                                            {`(${sortedCalendarDates[calendarIndex].month}/${sortedCalendarDates[calendarIndex].dayOfMonth})`}
                                        </Typography>
                                        <Typography 
                                            fontSize={14}
                                        >
                                            {`${daysOfWeek[sortedCalendarDates[calendarIndex].dayOfWeek]}`}
                                        </Typography>
                                    </Box>
                        }
                    </Box>
                sections.push(dayBox);
                calendarIndex++;
            } else if (i > 0) {
                // rendering the blue squares
    
                const margins = {
                    marginLeft,
                    marginRight,
                    marginTop,
                    marginBottom
                };

                const {row, col} = indexToCoords(sectionIndex, numTimes);
                const overlaps = col >= leftMost && col <= rightMost && row <= bottomMost && row >= topMost;
                const usersAvailable = (pageState.groupTimes as string[][])[sectionIndex];
                const available = new Set(usersAvailable);

                const section = 
                    <Section 
                        key={`section-${i}`}
                        margin={margins}
                        width={width}
                        height={height}
                        disabled={disabled}
                        available={available}
                        index={sectionIndex}
                        overlaps={overlaps}
                    />
                sections.push(section);
                sectionIndex += 1;
            }
        }

        return sections;
    }

    if (containerWidth === undefined || containerHeight === undefined || !pageState.submittedName) {
        return null;
    }

    return (
        <Box style={{marginRight: width/2}}>
            <Box
                border={containerBorderWidth}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    width: containerWidth, 
                    height: containerHeight, 
                    borderColor: 'primary.main',
                }}
            >
                {renderSections()}
            </Box>
        </Box>
    )
};

export default TimeTable;