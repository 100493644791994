import axios from "axios";
import { config } from '../config/config';
import { v4 as uuidv4 } from 'uuid';
import { AddUserParams, Day, EventDetails, UpdateTimeParams } from "../utils/types";
import { HALF_HOUR } from "../components/TimeChoosers/TimeChoosers";

/**
 * Creates new Event in DynamoDB `Events` table
 * 
 * @returns eventID if successfully created an event
 */
const createEvent = async (eventDetails: EventDetails): Promise<string | void> => {
    const eventID = uuidv4();

    const url = config.url.EVENTS_URL + '/createEvent';
    const params = {
        eventID,
        eventName: eventDetails.eventName,
        firstTime: eventDetails.firstTime,
        lastTime: eventDetails.lastTime,
        isDaysOfWeek: eventDetails.isDaysOfWeek,
        days: eventDetails.days,
        dates: eventDetails.dates,
    };
    try {
        await axios.post(url, params);
        return eventID;
    } catch (err) {
        console.log(err);
    }
}

/**
 * Gets the name of the event given its ID
 * 
 * @param eventID
 * @returns eventName
 */
const getEvent = async(eventID: string): Promise<EventDetails | undefined> => {
    const url = config.url.EVENTS_URL + `/${eventID}`;
    try {
        const res = await axios.get(url);
        return res.data.body as EventDetails;
    } catch (err) {
        console.log(err);
    }
};

/**
 * sends user info for the event to DB
 */
const addUser = async (data: AddUserParams): Promise<void> => {
    const url = config.url.EVENTS_URL + `/addUser`;
    try {
        await axios.post(url, data);
    } catch (err) {
        console.log(err);
    }
};  


const updateTime = async(data: UpdateTimeParams): Promise<void> => {
    const url = config.url.TIMES_URL + `/updateTime`;

    const formattedData = {
        eventID: data.eventID,
        userID: data.userID,
        times: data.times,
    }

    try {
        await axios.post(url, formattedData);
    } catch (err) {
        console.log(err);
    }
};

export const getTimes = async (eventID: string) => {
    const url = config.url.TIMES_URL + `/getTimes/${eventID}`;
    try {
        const res = await axios.get(url);
        return res.data.body;
    } catch (err) {
        console.log(err);
    }
}


export { createEvent, getEvent, addUser, updateTime };