import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setTimes } from "../../store/settings";

export const HOURS_IN_A_DAY = 24;
export const NOON = 12;
export const EARLIEST_TIME = 0;
export const LAST_TIME = HOURS_IN_A_DAY;
export const NUM_TIME_SLOTS = (LAST_TIME - EARLIEST_TIME + 1)*2 - 1;
export const HALF_HOUR = 0.5;
export const INITIAL_START_TIME = 9;
export const INITIAL_LAST_TIME = 17;

export type HalfDay = 'am' | 'pm'

const TimeChoosers = (): JSX.Element => {

    const [firstTime, setFirstTime] = useState<number>(INITIAL_START_TIME);
    const [lastTime, setLastTime] = useState<number>(INITIAL_LAST_TIME);
    const [timeZone, setTimeZone] = useState<number>(0);
    const dispatch = useAppDispatch();

    const timeZones = ['EST', 'PST', 'MST', 'CST'];

    const firstTimeItems: JSX.Element[] = [];
    const lastTimeItems: JSX.Element[] = [];
    for (let i = 0; i < NUM_TIME_SLOTS; i++) {
        const value: number = EARLIEST_TIME + HALF_HOUR*i;
        const halfDay: HalfDay = 
            value >= NOON 
                ? value === HOURS_IN_A_DAY 
                    ? 'am' 
                    : 'pm' 
                : 'am';
        const preliminaryNumberTime = Math.floor(value)%NOON;
        const numberTime: string = `${preliminaryNumberTime === 0 ? NOON : preliminaryNumberTime}${i % 2 === 1 ? ':30' : ''}`;
        if (value !== LAST_TIME) {
            firstTimeItems.push(
                <MenuItem 
                    key={`first${i}-${numberTime} ${halfDay}`}
                    value={value}
                    disabled={value >= lastTime}
                >
                    {`${numberTime} ${halfDay}`}
                </MenuItem>
            )
        }
        if (value !== EARLIEST_TIME) {
            lastTimeItems.push(
                <MenuItem 
                    key={`last${i}-${numberTime} ${halfDay}`}
                    value={value}
                    disabled={value <= firstTime}
                >
                    {`${numberTime} ${halfDay}`}
                </MenuItem>
            )
        }
    }

    useEffect(() => {
        dispatch(setTimes({firstTime, lastTime}));
    }, []);

    return (
        <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel>From</InputLabel>
                <Select
                    sx={{backgroundColor: 'white'}}
                    value={firstTime}
                    label="First Time"
                    onChange={(event) => {
                        // must be at least 30 minutes before last time
                        const newFirstTime = Math.min(event.target.value as number, lastTime - HALF_HOUR);
                        setFirstTime(newFirstTime);
                        dispatch(setTimes({firstTime: newFirstTime}));
                    }}
                >
                {firstTimeItems}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel>To</InputLabel>
                <Select
                    sx={{backgroundColor: 'white'}}
                    value={lastTime}
                    label="Last Time"
                    onChange={(event) => {
                        // must be at least 30 minutes after start time
                        const newLastTime = Math.max(event.target.value as number, firstTime + HALF_HOUR);
                        setLastTime(newLastTime);
                        dispatch(setTimes({lastTime: newLastTime}));
                    }}
                >
                    {lastTimeItems}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel>Time Zone</InputLabel>
                <Select
                    sx={{backgroundColor: 'white'}}
                    value={timeZone}
                    label="Time Zone"
                    onChange={(event) => {
                        setTimeZone(event.target.value as number);
                        dispatch(setTimes({timeZone: timeZones[event.target.value as number]}));
                    }}
                >
                    {timeZones.map((zone: string, index: number) => {
                        return (
                            <MenuItem 
                                value={index}
                                key={`time zone ${index}`}
                            >
                                {zone}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}

export default TimeChoosers;