import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { useAppDispatch } from "../../store/hooks";
import { setJoining, setLoadingEvent } from "../../store/pageState";
import { ANIMATION_TIME } from "../../utils/constants";
import theme from "../../utils/theme";

const NotFound = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [springs, api] = useSpring(() => ({
        from: { opacity: 0 },
    }));

    const fadeIn = () => {
        api.start({
            from: { opacity: 0 },
            to: { opacity: 1 },
        })
    };

    useEffect(() => {
        dispatch(setJoining({joining: false}));
        dispatch(setLoadingEvent({loadingEvent: false}));
        setTimeout(() => {
            fadeIn();
        }, ANIMATION_TIME);
    }, []);

    return (
        <animated.div style={{height: '100vh', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', ...springs, paddingLeft: 20, paddingRight: 20 }}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant="h2" sx={{mb: 20}} >Event Not Found</Typography>
                <Typography variant="h5" sx={{mb: 30, textAlign: 'center'}} fontStyle="italic">It looks like the event you are looking for doesn't exist, or has expired.</Typography>
                <Button 
                    color={"primary"}
                    variant={"outlined"}
                    sx={{width: 200, alignSelf: 'center', textTransform: 'none'}}
                    onClick={() => {
                        navigate('/', {replace: true});
                    }}
                >
                    <Typography>
                        Go To Home Page
                    </Typography>
                </Button>
            </Box>
        </animated.div>
    )
}

export default NotFound;