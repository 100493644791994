import { Box } from '@mui/material';
import { Calendar as CalendarElement, CalendarChangeEvent } from 'primereact/calendar';
import { useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { setCalendarDates } from '../../store/settings';
        
const Calendar = (): JSX.Element => {
    const [dates, setDates] = useState<string | Date | Date[] | null | undefined>(null);
    const dispatch = useAppDispatch();

    return (
        <CalendarElement placeholder='Add Dates' value={dates} showIcon showButtonBar onClearButtonClick={() => {
            setDates([]);
            dispatch(setCalendarDates({
                calendarDates: [],
            }));
        }} onChange={(e: CalendarChangeEvent) => {
            setDates(e.value);
            if (e.value instanceof Array) {
                dispatch(setCalendarDates({
                    calendarDates: e.value.map((val: Date) => {
                        return {
                            dayOfWeek: val.getDay(),
                            month: val.getMonth(),
                            dayOfMonth: val.getDate(),
                        }
                    })
                }));
            }
        }} selectionMode="multiple" readOnlyInput />
    )
}

export default Calendar;