import { Alert, Box, Slide, Snackbar, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { AlertHideReason, Day, TimeDetails } from "../../utils/types";
import { useAppDispatch } from "../../store/hooks";
import { useGetSettingsInfo } from "../../store/selectors/settingSelector";
import { useGetSystemInfo } from "../../store/selectors/systemSelector";
import { setWarningAlert } from "../../store/system";
import { useGetPageState } from "../../store/selectors/pageStateSelector";
import { addTimesUpdating, addUsersResponded, removeTimesUpdating } from "../../store/settings";
import { addGroupTime, removeGroupTime, setIndices, setIsAdding } from "../../store/pageState";
import { alterColor, sectionInGroupTimes } from "../../utils/functions";
import theme from "../../utils/theme";

export type SectionProps = {
    margin: {
        marginLeft: number,
        marginRight: number,
        marginTop: number,
        marginBottom: number,
    },
    width: number,
    height: number,
    disabled?: boolean,  // part of the group table
    available?: Set<string>,  // part of the group table and is available
    index: number,
    overlaps: boolean,  // is the user dragging on this cell?
}

const Section = ({margin, width, height, disabled, available, index, overlaps}: SectionProps): JSX.Element => {
    const pageState = useGetPageState();
    const settings = useGetSettingsInfo();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [mobileTouched, setMobileTouched] = useState<boolean>(false);

    // for personal table
    const shouldBeHighlighted = ((available && available.has(settings.userID as string)) && !(overlaps && pageState.isAdding === false))
        || (overlaps && pageState.isAdding === true);

    const [isChosen, setIsChosen] = useState<boolean>(shouldBeHighlighted);
    const [showNum, setShowNum] = useState<boolean>(false);
    const [showWarningAlert, setShowWarningAlert] = useState<boolean>(false);
    const systemInfo = useGetSystemInfo();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setIsChosen(shouldBeHighlighted);
    }, [pageState.startingIndex, pageState.endingIndex]);

    const enabled = pageState.submittedName as boolean && !disabled;
    const users: Set<string> = new Set();
    for (const cell of pageState.groupTimes as string[][]) {
        if (cell !== undefined) {
            for (const id of cell) {
                users.add(id);
            }
        }
    }

    const backgroundColor = 
        disabled
            ?   available !== undefined && available.size > 0 ? alterColor(theme.palette.secondary.main, '#0095ff', available.size/users.size) : 'secondary.main'
            :   enabled
                    ?   isChosen 
                            ? 'primary.main' 
                            : 'secondary.main'
                    :   'gray';
    const {marginLeft, marginRight, marginTop, marginBottom} = margin;

    const select = (): void => {
        if (enabled) {
            if (!isChosen) {
                // selecting now
                if (pageState.isAdding === undefined) {
                    dispatch(setIsAdding(true));
                }
                if (pageState.startingIndex === undefined) {
                    dispatch(setIndices({
                        startingIndex: index,
                        endingIndex: index,
                    }));
                } else {
                    dispatch(setIndices({
                        startingIndex: pageState.startingIndex,
                        endingIndex: index,
                    }));
                }
            } else {
                // deselecting
                if (pageState.isAdding === undefined) {
                    dispatch(setIsAdding(false));
                }
                if (pageState.startingIndex === undefined) {
                    dispatch(setIndices({
                        startingIndex: index,
                        endingIndex: index,
                    }));
                } else {
                    dispatch(setIndices({
                        startingIndex: pageState.startingIndex,
                        endingIndex: index,
                    }));
                }
            }
            setIsChosen(!isChosen);
        } else if (!systemInfo.warningAlertOpen && !disabled) {
            setShowWarningAlert(true);
            dispatch(setWarningAlert({warningAlertOpen: true}));
        }
    }

    const enter = () => {
        if (systemInfo.mouseDown) {
            select();
        }
        if (disabled) {
            setShowNum(true);
        }
    }

    const exit = () => {
        if (disabled) {
            setShowNum(false);
        }
    }

    return (
        <Box 
            id={`${index}-${disabled ? 'group' : 'personal'}`}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width,
                height,
                backgroundColor,
                marginLeft,
                marginRight,
                marginTop,
                marginBottom,
                '&:hover': {
                    backgroundColor: disabled ? null : (enabled ? (isChosen ? theme.palette.primary.main : 'white') : 'gray'),
                    cursor: showNum ? 'crosshair' : disabled ? 'inherit' : (enabled ? 'pointer' : 'default'),
                }
            }}
            onMouseEnter={() => {
                enter();
            }}
            onMouseLeave={() => {
                exit();
            }}
            onMouseDown={() => {
                select();
            }}
        >
            {
                showNum && <Typography sx={{userSelect: 'none'}}>{available?.size ?? 0}</Typography>
            }
            <Snackbar 
                open={showWarningAlert} 
                autoHideDuration={3000} 
                onClose={(_event, reason) => {
                    if (reason === AlertHideReason.TIMEOUT) {
                        setShowWarningAlert(false);
                        dispatch(setWarningAlert({warningAlertOpen: false}));
                    }
                }}
                TransitionComponent={Slide}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert 
                    onClose={() => {
                        setShowWarningAlert(false);
                        dispatch(setWarningAlert({warningAlertOpen: false}));
                    }} 
                    severity="warning"
                    sx={{userSelect: 'none'}}
                >
                    Enter your name before filling out your availability!
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Section;