import { Box, Button, MobileStepper, Step, StepLabel, Stepper, Typography, useMediaQuery } from "@mui/material";
import { useAppDispatch } from "../../store/hooks";
import { setInstructions } from "../../store/pageState";
import theme, { DARK_ORANGE, LIGHT_ORANGE } from "../../utils/theme";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { animated, useSpring } from "react-spring";
import { ANIMATION_TIME } from "../../utils/constants";
import { truncateSync } from "fs";

export const Instructions = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // mobile
    const [activeStep, setActiveStep] = useState<number>(0);

    // desktop
    const [currentStep, setCurrentStep] = useState<number>(0);

    // mobile
    const [touchingBack, setTouchingBack] = useState<boolean>(false);
    const [touchingNext, setTouchingNext] = useState<boolean>(false);
    const [touchingHome, setTouchingHome] = useState<boolean>(false);

    const steps = [
        'Create an event',
        'Enter your name or remain anonymous',
        'Enter your availability',
        'Send the event to your group',
        'Pick the best time'
    ];

    const orange = '#ffa640';

    const gifs = [
        require("../../assets/images/createEvent.gif"),
        require("../../assets/images/enterName.gif"),
        require("../../assets/images/enterAvailability.gif"),
        require("../../assets/images/shareEvent.gif"),
        require("../../assets/images/pickTime.gif"),
    ];

    const animTime = ANIMATION_TIME/8;

    const [opacitySprings, api] = useSpring(() => ({
        from: { opacity: 0 },
    }));

    const fadeIn = () => {
        api.start({
            from: { opacity: opacitySprings.opacity },
            to: { opacity: 1 },
            config: {
                duration: animTime
            }
        })
    };

    const fadeOut = () => {
        api.start({
            from: { opacity: opacitySprings.opacity },
            to: { opacity: 0 },
            config: {
                duration: animTime
            }
        })
    }

    useEffect(() => {
        fadeIn();
    }, []);

    
    if (!isMobile) {
        return (
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center', maxWidth: '70vw'}}>
                <Box border={1} sx={{display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', mr: 50, minWidth: 300, backgroundColor: theme.palette.primary.light, padding: 20, borderColor: theme.palette.primary.main, borderRadius: 5,}}>
                    <Typography variant="h3" sx={{mb: 20, userSelect: 'none'}} fontStyle={"italic"}>How It Works</Typography>
                    <Typography variant="h5" sx={{mb: 20, userSelect: 'none', textAlign: 'center'}}>
                        {`Time to meet is a service that allows you to find a meeting time that works for your whole group! Simply enter a ` + 
                        `name for your event and a time interval of possible meeting times. Then, add your personal availability and share the ` +
                        `link with the rest of your group. Once you find a time that works best for everyone, you're all set!`
                        }
                    </Typography>
                    <Box 
                        border={1}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '80%', 
                            height: 50, 
                            alignSelf: 'center', 
                            textTransform: 'none', 
                            mt: 20, 
                            mb: 10, 
                            borderColor: DARK_ORANGE,
                            backgroundColor: 'white',
                            borderRadius: 5,
                            '&:hover': {
                                borderColor: DARK_ORANGE,
                                backgroundColor: LIGHT_ORANGE,
                                cursor: 'pointer',
                            }
                        }}
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        <Typography sx={{color: DARK_ORANGE}} fontSize={15}>
                            Back to Home
                        </Typography>
                    </Box>
                </Box>
                <Box border={1} sx={{display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', backgroundColor: theme.palette.primary.light, padding: 20, borderColor: theme.palette.primary.main, borderRadius: 5}}>
                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Box
                            border={1}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '50%',
                                borderColor: currentStep > 0 ?  DARK_ORANGE : 'gray',
                                backgroundColor: 'white',
                                width: 50,
                                height: 50,
                                mr: 20,
                                '&:hover': {
                                    backgroundColor: currentStep > 0 ? LIGHT_ORANGE : 'white',
                                    cursor: currentStep > 0 ? 'pointer' : 'inherit',
                                }
                            }}
                            onClick={() => {
                                if (currentStep > 0) {
                                    fadeOut();
                                    setTimeout(() => {
                                        setCurrentStep(Math.max(0, currentStep - 1));
                                        fadeIn();
                                    }, animTime);
                                }
                            }}
                        >
                            <ArrowBackIosNewIcon 
                                fontSize={'large'} 
                                sx={{mr: 3, color: currentStep > 0 ? orange : 'gray'}} 
                            />
                        </Box>
                        <animated.div style={{...opacitySprings}}>
                            <Box 
                                border={2}
                                sx={{userSelect: 'none', height: 300, width: 500, backgroundColor: theme.palette.primary.dark, mb: 40, mt: 10, borderColor: theme.palette.primary.main, borderRadius: 10, objectFit: 'contain'}} 
                                component={'img'}
                                src={gifs[currentStep]}
                            />
                        </animated.div>
                        <Box
                            border={1}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '50%',
                                borderColor: currentStep < steps.length - 1 ?  DARK_ORANGE : 'gray',
                                backgroundColor: 'white',
                                width: 50,
                                height: 50,
                                ml: 20,
                                '&:hover': {
                                    backgroundColor: currentStep < steps.length - 1 ? LIGHT_ORANGE : 'white',
                                    cursor: currentStep < steps.length - 1 ? 'pointer' : 'inherit',
                                }
                            }}
                            onClick={() => {
                                if (currentStep < steps.length - 1) {
                                    fadeOut();
                                    setTimeout(() => {
                                        setCurrentStep(Math.min(steps.length - 1, currentStep + 1));
                                        fadeIn();
                                    }, animTime);
                                }
                            }}
                        >
                            <ArrowForwardIosIcon 
                                fontSize={'large'} 
                                sx={{ml: 3, color: currentStep < steps.length - 1 ? orange : 'gray'}} 
                            />
                        </Box>
                    </Box>
                    <Stepper activeStep={currentStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </Box>
        );
    }
  
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: 20, paddingRight: 20}}>
            <Typography border={1} variant="h6" sx={{mb: 20, userSelect: 'none', textAlign: 'center', backgroundColor: theme.palette.primary.light, borderColor: theme.palette.primary.main, borderRadius: 5, padding: 10}}>
                {`Time to meet is a service that allows you to find a meeting time that works for your whole group! Simply enter a ` + 
                `name for your event and a time interval of possible meeting times. Then, add your personal availability and share the ` +
                `link with the rest of your group. Once you find a time that works best for everyone, you're all set!`
                }
            </Typography>
            <Box border={1} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 5, padding: 10, borderColor: theme.palette.primary.main, backgroundColor: theme.palette.primary.light}}>
                <animated.div style={{...opacitySprings, width: '80%'}} >
                    <Box 
                        border={1}
                        sx={{userSelect: 'none', height: 250, width: '100%', backgroundColor: theme.palette.primary.dark, mb: 10, mt: 10, borderColor: theme.palette.primary.main, borderRadius: 10, objectFit: 'contain'}} 
                        component={'img'}
                        src={gifs[activeStep]}
                    />
                </animated.div>
                <MobileStepper
                    variant="dots"
                    steps={gifs.length}
                    position="static"
                    activeStep={activeStep}
                    sx={{ width: '100%', flexGrow: 1, backgroundColor: theme.palette.primary.light }}
                    nextButton={
                        <Box 
                            border={1}
                            sx={{
                                display: 'flex', 
                                flexDirection: 'row',
                                alignItems: 'center', 
                                justifyContent: 'center',
                                color: activeStep < 4 ? DARK_ORANGE : 'gray',
                                height: 40,
                                padding: 5,
                                borderRadius: 5,
                                backgroundColor: activeStep < 4 ? (touchingNext ? LIGHT_ORANGE : 'white') : 'white',
                                borderColor: activeStep < 4 ? DARK_ORANGE : 'gray',
                            }} 
                            onClick={() => {
                                if (activeStep < 4) {
                                    fadeOut();
                                    setTimeout(() => {
                                        handleNext();
                                        fadeIn();
                                    }, animTime);
                                }
                            }}
                            onTouchStart={() => {
                                setTouchingNext(true);
                            }}
                            onTouchEnd={() => {
                                setTouchingNext(false);
                            }}
                        >
                            <Typography sx={{userSelect: 'none', color: activeStep < 4 ? DARK_ORANGE : 'gray', ml: 8}}>Next</Typography>
                            <ArrowForwardIosIcon fontSize="small" color={'inherit'} sx={{ml: 5}}/>
                        </Box>
                    }
                    backButton={
                        <Box 
                            border={1}
                            sx={{
                                display: 'flex', 
                                flexDirection: 'row',
                                alignItems: 'center', 
                                justifyContent: 'center',
                                color: activeStep > 0 ? DARK_ORANGE : 'gray',
                                height: 40,
                                padding: 5,
                                borderRadius: 5,
                                backgroundColor: activeStep > 0 ? (touchingBack ? LIGHT_ORANGE : 'white') : 'white',
                                borderColor: activeStep > 0 ? DARK_ORANGE : 'gray',
                            }} 
                            onClick={() => {
                                if (activeStep > 0) {
                                    fadeOut();
                                    setTimeout(() => {
                                        handleBack();
                                        fadeIn();
                                    }, animTime);
                                }
                            }}
                            onTouchStart={() => {
                                setTouchingBack(true);
                            }}
                            onTouchEnd={() => {
                                setTouchingBack(false);
                            }}
                        >
                            <ArrowBackIosNewIcon fontSize="small" color={'inherit'} sx={{mr: 5}}/>
                            <Typography sx={{userSelect: 'none', color: activeStep > 0 ? DARK_ORANGE : 'gray', mr: 8}}>Back</Typography>
                        </Box>
                    }
                />
            </Box>
            <Box 
                border={1}
                sx={{
                    display: 'flex',
                    position: 'absolute',
                    bottom: 40,
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '80%', 
                    alignSelf: 'center', 
                    textTransform: 'none',
                    borderColor: DARK_ORANGE, 
                    backgroundColor: touchingHome ? LIGHT_ORANGE :  'white',
                    height: 60,
                    borderRadius: 5,
                }}
                onClick={() => {
                    navigate('/');
                }}
                onTouchStart={() => {
                    setTouchingHome(true);
                }}
                onTouchEnd={() => {
                    setTouchingHome(false);
                }}
            >
                <Typography sx={{color: DARK_ORANGE}} fontSize={20}>
                    Back to Home
                </Typography>
            </Box>
        </Box>   
    );
  
}

export default Instructions;