import { Day, TimeDetails, WindowDimensions } from "./types";
import { Interval } from "./classes";

/**
 * Gets the window dimensions
 * 
 * @returns object describing what the width and height of the broswer window is
 */
export const getWindowDimensions = (): WindowDimensions => {
    const hasWindow: boolean = typeof window !== undefined;
    const width = hasWindow ? window.innerWidth : undefined;
    const height = hasWindow ? window.innerHeight : undefined;
    return { width, height };
};

/**
 * Determines if a given "square" of the time table has been chosen as an available slot by `userID` at time `time` on
 * day `day`. The square is a 30 minute time slot that starts at time
 * 
 * @param groupTimes all the times that someone has said they are available at
 * @param userID user's unique identifier
 * @param day day to query
 * @param time must be an integer from 0 to 24 (inclusive) that is divisible by 0.5
 * @returns 
 */
export const sectionInGroupTimes = (groupTimes: TimeDetails[] | undefined, userID: string, day: Day, time: number): boolean => {
    if (time < 0 || time > 24 || time % 0.5 !== 0) {
        throw new Error("time precondition not met!");
    }

    if (groupTimes === undefined) {
        return false;
    }

    for (const timeDetail of groupTimes) {
        const existingDay = timeDetail.day;
        if (day === existingDay && timeDetail.userID === userID) {
            if (time == timeDetail.startTime) {
                return true;
            }
        }
    }

    return false;
}

/**
 * Determines whether a given string is a valid event name, or says why not
 * 
 * @param eventName candidate event name for an event
 * @returns true if `eventName` is a valid event name. Otherwise, returns a string describing
 *          why it is not a valid event name.
 */
export const isValidEventName = (eventName: string | undefined): true | string => {
    if (eventName === undefined) {
        return 'Event name cannot be empty';
    }
    if (eventName.length < 3) {
        return 'Event name must be at least 3 characters';
    }
    if (eventName.length > 20) {
        return 'Event name must be at most 20 characters';
    }
    return true;
};

export const isValidDates = (settings: any): true | string => {
    if (settings.daysOfWeek) {
        if (settings.days.filter((val: boolean) => val === true).length === 0) {
            return 'You must have at least one day of the week';
        }
    } else {
        if (settings.calendarDates.length === 0) {
            return 'You must choose at least one day';
        }
        if (settings.calendarDates.length > 10) {
            return 'You can only have up to 10 calendar dates';
        }
    }

    return true;
} 

/**
 * Generates a new set of times to go into the database
 * 
 * @param oldTimes times that the database has currently. Must have all 7 days as keys
 * @param newTimes times that the user wants to either add or remove, specified by `isAdding`. Must have all 7 days as keys
 * @param isAdding true iff the user is adding times. The alternative is removing times (removing availability)
 */
export const calculateNewTimes = (oldTimes: Map<Day, Array<Interval>>, newTimes: Map<Day, Array<Interval>>, isAdding: boolean): Map<Day, Array<Interval>> => {
    const out: Map<Day, Array<Interval>> = getEmptyDayMap<Interval>();

    for (const day of out.keys()) {
        const oldDayTimes: Array<Interval> | undefined = oldTimes.get(day);
        const newDayTimes: Array<Interval> | undefined = newTimes.get(day);
        if (oldDayTimes === undefined || newDayTimes === undefined) {
            throw new Error("Precondition not satisfied: oldTimes and newTimes must have keys for all 7 days of the week.");
        }

        const outDayTimes: Array<Interval> = calculateNewIntervals(oldDayTimes, newDayTimes, isAdding);
        out.set(day, outDayTimes);
    }

    return out;
}

/**
 * Calculates new time intervals for a given day, depending on if the user is adding or removing
 * 
 * @param oldDayTimes 
 * @param newDayTimes 
 * @param isAdding 
 */
const calculateNewIntervals = (oldDayTimes: Array<Interval>, newDayTimes: Array<Interval>, isAdding: boolean): Array<Interval> => {
    const outDayTimes: Array<Interval> = [];

    if (isAdding) {
        const allTimes: Set<number> = new Set();
        for (const timeCollection of [oldDayTimes, newDayTimes]) {
            for (const interval of timeCollection) {
                for (let time = interval.startTime; time < interval.endTime; time += 0.5) {
                    allTimes.add(time);
                }
            }
        }
        outDayTimes.push(...calculateIntervalsFromSet(allTimes));
    } else {
        const allTimes: Set<number> = new Set();
        for (const interval of oldDayTimes) {
            for (let time = interval.startTime; time < interval.endTime; time += 0.5) {
                allTimes.add(time);
            }
        }
        for (const interval of newDayTimes) {
            for (let time = interval.startTime; time < interval.endTime; time += 0.5) {
                allTimes.delete(time);
            }
        }
        outDayTimes.push(...calculateIntervalsFromSet(allTimes));
    }

    return outDayTimes;
};

/**
 * Calculate intervals from a set of times. All times must be between 0 and 24 (inclusive) and be
 * divisible by 0.5
 * 
 * @param allTimes set of times a user is available (starting time of 30-min block)
 * @return array of intervals that captures all the times in `allTimes` and no other times,
 * in the least number of intervals possible
 */
const calculateIntervalsFromSet = (allTimes: Set<number>): Array<Interval> => {
    const timesArray: Array<number> = [...allTimes].sort((a, b) => a - b);
    const intervals: Array<Interval> = [];

    let startTime: number | undefined = undefined;
    let lastSeenTime: number | undefined = undefined;
    for (const currentTime of timesArray) {
        if (currentTime < 0 || currentTime > 24 || currentTime % 0.5 !== 0) {
            throw new Error("Does not satisfy the precondition!");
        }

        if (startTime === undefined || lastSeenTime === undefined) {
            startTime = currentTime;
            lastSeenTime = currentTime;
            continue;
        }

        if (currentTime - lastSeenTime > 0.5) {
            // new interval!
            intervals.push(new Interval(startTime, lastSeenTime + 0.5));
            startTime = currentTime;
        }

        lastSeenTime = currentTime;
    }

    if (startTime !== undefined && lastSeenTime !== undefined) {
        intervals.push(new Interval(startTime, lastSeenTime + 0.5));
    }

    return intervals;
}

/**
 * Gathers data from objects and separates them by their `adding` attribute and returns them as a map
 * 
 * @param times 
 */
export const getTimeMapsFromObject = (times: Array<{day: Day, time: number, adding: boolean}> | undefined): { adding: Map<Day, Array<Interval>>, removing: Map<Day, Array<Interval>>} => {
    if (times === undefined) {
        return { adding: getEmptyDayMap(), removing: getEmptyDayMap() };
    }

    const adding: Map<Day, Array<Interval>> = getEmptyDayMap<Interval>();
    const removing: Map<Day, Array<Interval>> = getEmptyDayMap<Interval>();

    const addingDayMap: Map<Day, Set<number>> = getEmptyDaySet<number>();
    const removingDayMap: Map<Day, Set<number>> = getEmptyDaySet<number>();
    for (const {day, time, adding} of times) {
        const set: Set<number> | undefined = adding ? addingDayMap.get(day) : removingDayMap.get(day);
        set?.add(time);
    }

    let index = 0;
    for (const dayMap of [addingDayMap, removingDayMap]) {
        for (const [day, times] of dayMap) {
            if (index === 0) {
                adding.set(day, calculateIntervalsFromSet(times));
            } else {
                removing.set(day, calculateIntervalsFromSet(times));
            }
        }
        index += 1;
    }

    return { adding, removing };
}

/**
 * Returns a map mapping all days in the Day enum to an empty array of type T
 */
const getEmptyDayMap = <T>(): Map<Day, Array<T>> => {
    const out: Map<Day, Array<T>> = new Map([
        [Day.SUN, []],
        [Day.MON, []],
        [Day.TUE, []],
        [Day.WED, []],
        [Day.THU, []],
        [Day.FRI, []],
        [Day.SAT, []],
    ]);

    return out;
}

/**
 * Returns a map mapping all days in the Day enum to an empty set
 */
const getEmptyDaySet = <T>(): Map<Day, Set<T>> => {
    const out: Map<Day, Set<T>> = new Map([
        [Day.SUN, new Set()],
        [Day.MON, new Set()],
        [Day.TUE, new Set()],
        [Day.WED, new Set()],
        [Day.THU, new Set()],
        [Day.FRI, new Set()],
        [Day.SAT, new Set()],
    ]);

    return out;
}

/**
 * Turns times from type {day: Day, time: number} into Map<Day, Interval[]>
 * @param times
 */
export const getUserTimesFromMap = (times: Map<Day, Interval[]>): {day: Day, time: number}[] => {
    const out: {day: Day, time: number}[] = [];

    for (const [day, intervals] of times) {
        for (const interval of intervals) {
            for (let time = interval.startTime; time < interval.endTime; time += 0.5) {
                out.push({day, time});
            }
        }
    }

    return out;
}

/**
 * https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
 * @param c 
 * @returns 
 */
const componentToHex = (c: number): string => {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

/**
 * https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
 * @param r 
 * @param g 
 * @param b 
 * @returns 
 */
export const rgbToHex = (r: number, g: number, b: number): string => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

/**
 * https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
 * @param hex 
 * @returns 
 */
export const hexToRgb = (hex: string): {r: number, g: number, b: number} | null => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

/**
 * Given a baseColor hex color and and endColor hex color and a percentage, return a color that is
 * in the linear gradient between the two colors, where it is equal to baseColor when `percentFromBase` === 0.
 * @param baseColor 
 * @param percentFromBase Must be between 0 and 1
 */
export const alterColor = (baseColor: string, endColor: string, percentFromBase: number): string => {
    if (percentFromBase < 0 || percentFromBase > 1) {
        throw new Error("Percent is not between 0 and 1!");
    }

    const firstColor: {r: number, g: number, b: number} | null = hexToRgb(baseColor);
    const secondColor: {r: number, g: number, b: number} | null = hexToRgb(endColor);

    if (firstColor === null || secondColor === null) {
        throw new Error("Could not alter colors!");
    }

    const weightedAverageFunction = (color1: number, color2: number, percent: number) => {
        return Math.floor(color1*(1 - percent) + color2*percent);
    }

    return rgbToHex(
        weightedAverageFunction(firstColor.r, secondColor.r, percentFromBase),
        weightedAverageFunction(firstColor.g, secondColor.g, percentFromBase),
        weightedAverageFunction(firstColor.b, secondColor.b, percentFromBase),
    );
}

/**
 * Top row is 0, left col is 0
 * 
 * @param index index of cell (top left is 0, bottom right is last index, column major order)
 * @param numRows self-explanatory
 */
export const indexToCoords = (index: number, numRows: number): {row: number, col: number} => {
    const x = Math.floor(index/numRows);
    const y = index % numRows;
    return {row: y, col: x};
}   

export const getIndicesFromPositions = (first: number, second: number, numRows: number): Array<number> => {
    const firstPos = indexToCoords(first, numRows);
    const secondPos = indexToCoords(second, numRows);

    const leftMost = Math.min(firstPos.col, secondPos.col);
    const rightMost = Math.max(firstPos.col, secondPos.col);
    const topMost = Math.min(firstPos.row, secondPos.row);
    const bottomMost = Math.max(firstPos.row, secondPos.row);

    const out: Array<number> = [];
    for (let index = 0; index <= (rightMost+1)*numRows; index++) {
        const { row, col } = indexToCoords(index, numRows);
        if (row <= bottomMost && row >= topMost && col >= leftMost && col <= rightMost) {
            out.push(index);
        }
    }

    return out;
}