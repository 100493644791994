import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import theme from './utils/theme';
import store from './store';
import Instructions from './containers/Instructions';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/how-to" element={<Instructions />} />
            <Route path="/:eventID" element={<Home />} />
            <Route path="/notfound" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ReduxProvider>
    </ThemeProvider>
  );
}

export default App;
